div,
button {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version */
}

#background {
  top: 0px;
  left: 0px;
  width: 80%;
  height: 100%;
  background-color: #f2f2f2;
}

.flag_section {
  background-image: linear-gradient(to right, #c02f48, #ef9c27);
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  padding-right: 44px;
}

.buttonEnterSpace {
  background-color: #b47620;
}
.buttonEnterSpace:hover {
  background-color: white;
  color: #b47620;
  border-color: #b47620;
}
